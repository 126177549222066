export default [
  {
    path: '/',
    redirect: '/user/login',
    layout: false,
  },
  {
    path: '/user',
    layout: false,
    routes: [
      {
        path: '/user/login',
        layout: false,
        name: 'login',
        component: './user/Login',
      },
      {
        name: 'register',
        icon: 'smile',
        path: '/user/register',
        component: './user/register',
      },
      {
        component: '404',
      },
    ],
  },
  {
    path: '/account',
    routes: [
      {
        path: '/account/center',
        // layout: false,
        name: 'center',
        component: './user/Center',
      },
    ],
  },
  {
    layout: false,
    path: '/screen',
    component: './MonitorScreen/Content',
    routes: [
      {
        path: '/screen/home',
        layout: false,
        component: './MonitorScreen/Home',
      },
      {
        path: '/screen/device',
        layout: false,
        component: './MonitorScreen/Device',
      },
    ],
  },
  {
    name: '首页',
    icon: 'icon-a-shouye2x',
    path: '/HomePage',
    component: './HomePage',
    class: 'homepage',
    access: 'normalRouteFilter',
  },
  {
    name: '农机大数据',
    path: 'deviceMonitor',
    routes: [
      {
        name: '位置分布',
        path: 'position',
        component: './DeviceMonitor/DeviceSite',
        access: 'normalRouteFilter',
      },
      {
        name: '作业统计',
        path: 'deviceStatus',
        component: './DeviceMonitor/DeviceStatus',
        access: 'normalRouteFilter',
      },
      {
        name: '农机监控',
        path: 'deviceOil',
        component: './DeviceMonitor/DeviceOil',
        access: 'normalRouteFilter',
      },
      {
        name: '农机测试',
        path: 'deviceTest',
        component: './DeviceMonitor/DeviceTest',
        access: 'normalRouteFilter',
      },
      {
        name: '运行数据',
        path: 'runningData',
        access: 'normalRouteFilter',
        component: './DeviceMonitor/DeviceOil/RunningData',
        hideInMenu: true,
      },
      {
        name: '农机详情',
        path: '/DeviceMonitor/DeviceOil/DeviceDetail',
        component: './DeviceMonitor/DeviceDetail',
        access: 'normalRouteFilter',
        hideInMenu: true,
      },
    ],
  },
  {
    name: '设备管理',
    path: 'equipmentManage',
    routes: [
      {
        name: '农机管理',
        path: 'machine',
        component: './EquipmentManage/Machine',
        access: 'normalRouteFilter',
      },
      {
        name: '终端管理',
        path: 'terminal',
        component: './EquipmentManage/Terminal',
        access: 'normalRouteFilter',
      },
      {
        name: 'SIM卡管理',
        path: 'sim',
        component: './EquipmentManage/Sim',
        access: 'normalRouteFilter',
      },
      {
        name: '设备品类',
        path: 'category',
        component: './EquipmentManage/Category',
        access: 'normalRouteFilter',
      },
      {
        name: '农具管理',
        path: 'farmToolsManage',
        component: './EquipmentManage/FarmToolsManage',
        access: 'normalRouteFilter',
      },
    ],
  },
  {
    name: '故障管理',
    path: 'machineManage',
    routes: [
      {
        name: '故障码配置',
        path: 'dodeSet',
        component: './MachineManage/CodeSet',
        access: 'normalRouteFilter',
      },
      {
        name: '故障记录',
        path: 'errRecord',
        component: './MachineManage/ErrRecord',
        access: 'normalRouteFilter',
      },
      {
        name: '触发分析',
        path: 'analysis',
        component: './MachineManage/Analysis',
        access: 'normalRouteFilter',
      },
    ],
  },
  {
    name: '大屏管理',
    path: 'view',
    routes: [
      {
        name: '定制大屏',
        path: 'home',
      },
    ],
  },
  {
    name: 'OTA管理',
    path: 'ota',
    routes: [
      {
        name: 'APP管理',
        path: 'app',
        component: './Ota/AppManage',
      },
      {
        name: '升级包管理',
        path: 'upgrade',
        component: './Ota/OtaUpgrade',
      },
    ],
  },
  {
    name: '系统管理',
    path: '/system',
    access: 'adminRouteFilter',
    routes: [
      {
        name: '用户管理',
        path: 'user',
        component: './System/User',
        access: 'adminRouteFilter',
      },
      {
        name: '权限管理',
        path: 'permission',
        component: './System/Permission',
        access: 'adminRouteFilter',
      },
      {
        name: '个人中心',
        path: 'menus',
        component: './System/Menu',
        access: 'adminRouteFilter',
      },
      {
        name: '企业客户管理',
        path: 'enterprise',
        component: './System/Enterprise',
        access: 'adminRouteFilter',
      },
    ],
  },
  {
    name: '客户管理',
    path: '/client',
    access: 'adminRouteFilter',
    routes: [
      {
        name: '车辆管理',
        path: 'machine',
        component: './ClientManage/Machine',
        access: 'adminRouteFilter',
      },
      {
        name: '故障管理',
        path: 'fault',
        component: './ClientManage/Fault',
        access: 'adminRouteFilter',
      },
      {
        name: '用户管理',
        path: 'user',
        component: './ClientManage/User',
        access: 'adminRouteFilter',
      },
    ],
  },

  // {
  //   name: '数据报表',
  //   path: 'http://10.9.127.245:8001/datart/organizations/da59116a54d44239bfbf8e0c0115c167/vizs/storyPlayer/898c0932e98f4f6e8b9dcfaf71d5fe60',
  // },
  {
    component: '404',
  },
];
