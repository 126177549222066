// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/data/jenkins_data/workspace/f_hx-iov-prod/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/index.html",
        "redirect": "/user/login",
        "layout": false,
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/user/login",
        "layout": false,
        "exact": true
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user/login",
            "layout": false,
            "name": "login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/user/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "register",
            "icon": "smile",
            "path": "/user/register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/user/register'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/account",
        "routes": [
          {
            "path": "/account/center",
            "name": "center",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Center' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/user/Center'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "layout": false,
        "path": "/screen",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MonitorScreen__Content' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/MonitorScreen/Content'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/screen/home",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MonitorScreen__Home' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/MonitorScreen/Home'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/screen/device",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MonitorScreen__Device' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/MonitorScreen/Device'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "首页",
        "icon": "icon-a-shouye2x",
        "path": "/HomePage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HomePage' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/HomePage'), loading: LoadingComponent}),
        "class": "homepage",
        "access": "normalRouteFilter",
        "exact": true
      },
      {
        "name": "农机大数据",
        "path": "/deviceMonitor",
        "routes": [
          {
            "name": "位置分布",
            "path": "/deviceMonitor/position",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceMonitor__DeviceSite' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/DeviceMonitor/DeviceSite'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "作业统计",
            "path": "/deviceMonitor/deviceStatus",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceMonitor__DeviceStatus' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/DeviceMonitor/DeviceStatus'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "农机监控",
            "path": "/deviceMonitor/deviceOil",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceMonitor__DeviceOil' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/DeviceMonitor/DeviceOil'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "农机测试",
            "path": "/deviceMonitor/deviceTest",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceMonitor__DeviceTest' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/DeviceMonitor/DeviceTest'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "运行数据",
            "path": "/deviceMonitor/runningData",
            "access": "normalRouteFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceMonitor__DeviceOil__RunningData' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/DeviceMonitor/DeviceOil/RunningData'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "农机详情",
            "path": "/DeviceMonitor/DeviceOil/DeviceDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceMonitor__DeviceDetail' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/DeviceMonitor/DeviceDetail'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "设备管理",
        "path": "/equipmentManage",
        "routes": [
          {
            "name": "农机管理",
            "path": "/equipmentManage/machine",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManage__Machine' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/EquipmentManage/Machine'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "终端管理",
            "path": "/equipmentManage/terminal",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManage__Terminal' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/EquipmentManage/Terminal'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "SIM卡管理",
            "path": "/equipmentManage/sim",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManage__Sim' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/EquipmentManage/Sim'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "设备品类",
            "path": "/equipmentManage/category",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManage__Category' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/EquipmentManage/Category'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "农具管理",
            "path": "/equipmentManage/farmToolsManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManage__FarmToolsManage' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/EquipmentManage/FarmToolsManage'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          }
        ]
      },
      {
        "name": "故障管理",
        "path": "/machineManage",
        "routes": [
          {
            "name": "故障码配置",
            "path": "/machineManage/dodeSet",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MachineManage__CodeSet' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/MachineManage/CodeSet'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "故障记录",
            "path": "/machineManage/errRecord",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MachineManage__ErrRecord' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/MachineManage/ErrRecord'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          },
          {
            "name": "触发分析",
            "path": "/machineManage/analysis",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MachineManage__Analysis' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/MachineManage/Analysis'), loading: LoadingComponent}),
            "access": "normalRouteFilter",
            "exact": true
          }
        ]
      },
      {
        "name": "大屏管理",
        "path": "/view",
        "routes": [
          {
            "name": "定制大屏",
            "path": "/view/home",
            "exact": true
          }
        ]
      },
      {
        "name": "OTA管理",
        "path": "/ota",
        "routes": [
          {
            "name": "APP管理",
            "path": "/ota/app",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Ota__AppManage' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/Ota/AppManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "升级包管理",
            "path": "/ota/upgrade",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Ota__OtaUpgrade' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/Ota/OtaUpgrade'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "系统管理",
        "path": "/system",
        "access": "adminRouteFilter",
        "routes": [
          {
            "name": "用户管理",
            "path": "/system/user",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__User' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/System/User'), loading: LoadingComponent}),
            "access": "adminRouteFilter",
            "exact": true
          },
          {
            "name": "权限管理",
            "path": "/system/permission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__Permission' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/System/Permission'), loading: LoadingComponent}),
            "access": "adminRouteFilter",
            "exact": true
          },
          {
            "name": "个人中心",
            "path": "/system/menus",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__Menu' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/System/Menu'), loading: LoadingComponent}),
            "access": "adminRouteFilter",
            "exact": true
          },
          {
            "name": "企业客户管理",
            "path": "/system/enterprise",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__Enterprise' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/System/Enterprise'), loading: LoadingComponent}),
            "access": "adminRouteFilter",
            "exact": true
          }
        ]
      },
      {
        "name": "客户管理",
        "path": "/client",
        "access": "adminRouteFilter",
        "routes": [
          {
            "name": "车辆管理",
            "path": "/client/machine",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientManage__Machine' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/ClientManage/Machine'), loading: LoadingComponent}),
            "access": "adminRouteFilter",
            "exact": true
          },
          {
            "name": "故障管理",
            "path": "/client/fault",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientManage__Fault' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/ClientManage/Fault'), loading: LoadingComponent}),
            "access": "adminRouteFilter",
            "exact": true
          },
          {
            "name": "用户管理",
            "path": "/client/user",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ClientManage__User' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/ClientManage/User'), loading: LoadingComponent}),
            "access": "adminRouteFilter",
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/data/jenkins_data/workspace/f_hx-iov-prod/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
